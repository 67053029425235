<template>
  <v-row class="match-height">
    <!-- horizontal -->
    <v-col cols="12" md="12">
      <v-card>
        <v-card-title
          >Detail Simpanan Anggota
          <v-spacer></v-spacer>
          <router-link :to="{ name: 'daftar-simpanan' }">
            <v-btn small color="primary">Kembali</v-btn>
          </router-link>
        </v-card-title>
        <v-simple-table dense style="width: 100%">
          <tbody>
            <tr>
              <td><b>Kode Anggota</b> : {{ item.kode_anggota }}</td>
              <td><b>Nama</b> : {{ item.nama }}</td>
              <td>
                <b>Wilayah</b> :
                <span v-if="item.wilayah_luar === '1'" style="color: red">{{ item.wilayah }}</span>
                <span v-else>{{ item.wilayah }}</span>
              </td>
            </tr>
            <tr>
              <td><b>Tgl Lahir</b> : {{ formatDate(item.tgl_lahir) }}</td>
              <td><b>NIK</b> : {{ item.nik }}</td>
              <td><b>Jenis Upah</b> : {{ item.jenis_upah }}</td>
            </tr>
            <tr>
              <td><b>Tgl Masuk</b> : {{ formatDate(item.tgl_masuk) }}</td>
              <td><b>Jabatan</b> : {{ item.jabatan }}</td>
              <td><b>Jumlah Upah : </b>{{ new Intl.NumberFormat(['id']).format(item.jumlah_upah) }}</td>
            </tr>
            <tr>
              <td><b>Total Simpanan : </b>{{ new Intl.NumberFormat(['id']).format(item.total_simpanan) }}</td>
              <td></td>
            </tr>
          </tbody>
        </v-simple-table>

        <v-data-table dense :headers="headers" :items="desserts" :loading="loading" class="elevation-1">
          <template v-slot:top>
            <v-toolbar flat>
              <v-dialog v-model="dialog" max-width="500px">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on"> Tambah </v-btn>
                </template>
                <v-card>
                  <v-card-title>
                    <span class="text-h5">Tambah</span>
                  </v-card-title>

                  <v-card-text>
                    <v-form ref="form" v-model="valid" lazy-validation>
                      <v-row>
                        <v-col cols="12" md="4">
                          <label for="firstnameHorizontalIcons">Jenis Simpanan</label>
                        </v-col>
                        <v-col cols="12" md="8">
                          <v-select
                            v-model="defaultItem.id_jenis_simpanan"
                            :items="jenis_simpanans"
                            item-text="text"
                            item-value="value"
                            persistent-hint
                            return-object
                            single-line
                            dense
                            outlined
                            @change="jenisSimpananChange"
                          ></v-select>
                        </v-col>

                        <v-col cols="12" md="4">
                          <label for="firstnameHorizontalIcons">Tgl Simpanan</label>
                        </v-col>
                        <v-col cols="12" md="8">
                          <!-- <input type="date" v-model="defaultItem.tgl_simpanan" /> -->
                          <v-text-field
                            v-model="defaultItem.tgl_simpanan"
                            type="date"
                            dense
                            outlined
                          ></v-text-field>
                        </v-col>

                        <v-col cols="12" md="4">
                          <label for="firstnameHorizontalIcons">Besar Simpanan</label>
                        </v-col>
                        <v-col cols="12" md="8">
                          <v-text-field
                            v-if="is_fixed == false"
                            v-model="defaultItem.besar_simpanan"
                            outlined
                            dense
                            hide-details
                          ></v-text-field>
                          <v-text-field
                            v-else
                            v-model="defaultItem.besar_simpanan"
                            outlined
                            dense
                            hide-details
                            readonly
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="secondary" @click="close"> Batal </v-btn>
                    <v-btn color="primary" @click="save"> Simpan </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>

          <template v-slot:item.no="{ item, index }">
            {{ index + 1 }}
          </template>
          <template v-slot:item.besar_simpanan="{ item }">
            <div style="text-align: right">{{ new Intl.NumberFormat(['id']).format(item.besar_simpanan) }}</div>
          </template>
          <template v-slot:no-data>
            <v-btn color="primary" small @click="initialize"> Reload</v-btn>
          </template>
        </v-data-table>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import axios from 'axios'

const apiRoot = process.env.VUE_APP_APIURL

export default {
  data() {
    return {
      desserts: [],
      loading: true,
      headers: [
        { text: 'No', value: 'no', sortable: false },
        { text: 'Tanggal Simpanan', sortable: false, value: 'tanggal' },
        { text: 'Nama Simpanan', value: 'nama', sortable: false },
        { text: 'Besar Simpanan', value: 'besar_simpanan', sortable: false },
        { text: 'Jenis', value: 'jenis', sortable: false },
        { text: 'User Entry', value: 'name_user_entry', sortable: false },
      ],
      id_anggota: this.$route.params.id_anggota,
      dialog: false,
      defaultItem: {
        id_jenis_simpanan: { text: '', value: '' },
        besar_simpanan: 0,
        tgl_simpanan: new Date().toJSON().slice(0, 10).replace(/-/g, '-'),
      },
      jenis_simpanans: [],
      is_fixed: false,
      input_besar_simpanan: false,
      valid: true,
      item: {},
    }
  },
  created() {
    this.initialize()
  },
  computed: {
    token() {
      return this.$store.getters.token
    },
  },
  methods: {
    initialize() {
      this.getAnggota()
      this.getSimpanans()
    },
    getAnggota() {
      this.$store.dispatch('loading', true)
      const headers = {
        Authorization: this.token,
      }
      axios
        .get(`${apiRoot}/api/Simpanan/get/${this.id_anggota}`, { headers })
        .then(response => {
          if (response.data.code === 200) {
            this.item = Object.assign({}, response.data.data)
            this.item.total_simpanan = response.data.total_simpanan

            this.getJenisSimpanans()
          } else {
            alert(response.data.message)
          }
          this.$store.dispatch('loading', false)
        })
        .catch(error => {
          alert(error)
          this.$store.dispatch('loading', false)
        })
    },
    getSimpanans() {
      this.loading = true
      const headers = {
        Authorization: this.token,
      }
      axios
        .get(`${apiRoot}/api/Simpanan/getTable/${this.id_anggota}`, { headers })
        .then(response => {
          if (response.data.code === 401) {
            this.$store.dispatch('logout', 'Session berakhir!')
          } else if (response.data.code === 200) {
            // console.log(response.data.data)
            this.desserts = response.data.data
          } else {
            alert(response.data.message)
          }
          this.loading = false
        })
        .catch(error => {
          alert(error)
          this.loading = false
        })
    },
    getJenisSimpanans() {
      this.$store.dispatch('loading', true)
      const headers = {
        Authorization: this.token,
      }
      axios
        .get(`${apiRoot}/api/Simpanan/getJenis_byupah/${this.item.jenis_upah}`, { headers })
        .then(response => {
          if (response.data.code === 200) {
            this.jenis_simpanans = []
            const arr = response.data.data
            arr.forEach(element => {
              this.jenis_simpanans.push({
                text: element.nama,
                value: element.id,
              })
            })
          } else {
            alert(response.data.message)
          }
          this.$store.dispatch('loading', false)
        })
        .catch(error => {
          alert(error)
          this.$store.dispatch('loading', false)
        })
    },
    getBesarSimpanan() {
      this.$store.dispatch('loading', true)
      const headers = {
        Authorization: this.token,
      }
      axios
        .get(`${apiRoot}/api/Simpanan/getJenis/${this.defaultItem.id_jenis_simpanan.value}`, { headers })
        .then(response => {
          if (response.data.code === 401) {
            this.$store.dispatch('logout', 'Session berakhir!')
          } else if (response.data.code === 200) {
            // console.log(response.data.data)
            if (response.data.data.is_fixed === '0') {
              this.is_fixed = false
            } else {
              this.is_fixed = true
            }
            this.defaultItem.besar_simpanan = response.data.data.besar_simpanan
          } else {
            alert(response.data.message)
          }
          this.$store.dispatch('loading', false)
        })
        .catch(error => {
          alert(error)
          this.$store.dispatch('loading', false)
        })
    },
    jenisSimpananChange() {
      this.getBesarSimpanan()
      if (this.defaultItem.id_jenis_simpanan.value === '3') {
        this.input_besar_simpanan = true
      } else {
        this.input_besar_simpanan = false
      }
    },
    close() {
      this.dialog = false
    },
    save() {
      this.$store.dispatch('loading', true)
      const headers = {
        Authorization: this.token,
      }
      const form = new FormData()
      form.append('id_jenis_simpanan', this.defaultItem.id_jenis_simpanan.value)
      form.append('besar_simpanan', this.defaultItem.besar_simpanan)
      form.append('id_anggota', this.id_anggota)
      form.append('tgl_simpanan', this.defaultItem.tgl_simpanan)
      axios
        .post(`${apiRoot}/api/Simpanan/add`, form, { headers })
        .then(response => {
          if (response.data.code === 401) {
            this.$store.dispatch('logout', 'Session berakhir!')
          } else if (response.data.code === 200) {
            this.getSimpanans()
            this.getAnggota()
            this.dialog = false
          } else {
            alert(response.data.message)
          }
          this.$store.dispatch('loading', false)
        })
        .catch(error => {
          alert(error)
          this.$store.dispatch('loading', false)
        })
    },
  },
}
</script>
